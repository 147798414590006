



































































































































































































































































































































































import DeliveryOrder from "@/store/entities/delivery-order";
import DeliveryOrderStatus from "@/store/enums/delivery-order";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Compressor from "compressorjs";
import moment from "moment";
import { EventBus } from "./../lib/event";

@Component({
  components: {},
})
export default class TaskUncompleted extends Vue {
  statusKeys = DeliveryOrderStatus;
  currentDate = null;
  currentDoNo = null;
  itemLimit = 2;
  modal = false;
  form = {
    Id: null,
    Status: null,
    DriverDoNo: null,
    DriverDoImg: null,
    TicketNo: null,
    TicketImg: null,
    TicketCost: 0,
    TicketQty: 0,
    BehalfDoNo: null,
    BehalfDoImg: null,
    SupplierDoNo: null,
    SupplierDoImg: null,
    TollCount: 0,
    IsDocSupplierDoCollect: false,
    IsDocDriverDoCollect: false,
    IsDocBehalfOfDoCollect: false,
    IsDocTicketCollect: false,
  };
  processing = {
    card: false,
    dialog: false,
    screen:false,
  };
  dialog = false;
  rules = [
    (value) =>
      !value ||
      value.size < 15000000 ||
      "Image size should be less than 15 MB!",
  ];
  fileIcon = "mdi-camera";
  textIcon = "mdi-clipboard-text";
  acceptFileType = "image/png, image/jpeg, image/bmp";
  async reset() {
    this.form = {
      Id: null,
      Status: null,
      DriverDoNo: null,
      DriverDoImg: null,
      TicketNo: null,
      TicketImg: null,
      TicketCost: 0,
      TicketQty: 0,
      BehalfDoNo: null,
      BehalfDoImg: null,
      SupplierDoNo: null,
      SupplierDoImg: null,
      TollCount: 0,
      IsDocSupplierDoCollect: false,
      IsDocDriverDoCollect: false,
      IsDocBehalfOfDoCollect: false,
      IsDocTicketCollect: false,
    };
  }
  get items(): Array<DeliveryOrder> {
    if (!this.$store.state.deliveryOrder) return [];

    return this.$store.state.deliveryOrder.list.filter((x) => {
      let doDate = moment(x.DeliveryDate);
      let currentDate = moment(this.currentDate);
      return doDate.isSame(currentDate, "day");
    });
  }
  async init() {
    this.dialog = false;
    this.processing.screen = true;
    await this.$store.dispatch({
      type: "deliveryOrder/getToday",
    });
    this.currentDate = moment().format("YYYY-MM-DD");
    this.processing.screen = false;
    this.reset();
  }
  async created() {
    this.init();
  }
  async assigned(item) {
    try {
      this.processing.card = true;
      let result = await this.$store.dispatch({
        type: "deliveryOrder/assigned",
        data: item,
      });

      if (!result) {
        EventBus.$emit("on-error", {
          title: "Error",
          content: this.$store.state.deliveryOrder.messages,
        });
      }

      await this.init();
    } catch (err) {
      console.log(err);
    } finally {
      this.processing.card = false;
    }
  }
  async collected(item) {
    try {
      this.processing.card = true;
      let result = await this.$store.dispatch({
        type: "deliveryOrder/collected",
        data: item,
      });

      if (!result) {
        EventBus.$emit("on-error", {
          title: "Error",
          content: this.$store.state.deliveryOrder.messages,
        });
      }

      await this.init();
    } catch (err) {
      console.log(err);
    } finally {
      this.processing.card = false;
    }
  }
  async delivered() {
    try {
      const form: any = this.$refs.observer;
      let valid = await form.validate();
      if (!valid) return;

      this.processing.dialog = true;
      let result = await this.$store.dispatch({
        type: "deliveryOrder/delivered",
        data: this.form,
      });

      if (!result) {
        EventBus.$emit("on-error", {
          title: "Error",
          content: this.$store.state.deliveryOrder.messages,
        });
      }

      await this.init();
    } catch (err) {
      console.log(err);
    } finally {
      this.processing.dialog = false;
    }
  }
  async started(item) {
    try {
      this.processing.card = true;
      let result = await this.$store.dispatch({
        type: "deliveryOrder/started",
        data: item,
      });

      if (!result) {
        EventBus.$emit("on-error", {
          title: "Error",
          content: this.$store.state.deliveryOrder.messages,
        });
      }

      await this.init();
    } catch (err) {
      console.log(err);
    } finally {
      this.processing.card = false;
    }
  }
  async onFileChange(file, filename) {
    new Compressor(file, {
      convertSize: 1000000, // 1MB
      maxWidth: 800,
      quality: 1,
      success: (result) => (this.form[filename] = result),
    });
  }
  async setNext() {
    this.currentDate = moment(this.currentDate)
      .add("days", 1)
      .format("YYYY-MM-DD");
  }
  async setPrev() {
    this.currentDate = moment(this.currentDate)
      .add("days", -1)
      .format("YYYY-MM-DD");
  }

  @Watch("form", { immediate: true, deep: true })
  onPropertyChanged(value: string, oldValue: string) {
    if (this.form.DriverDoNo)
      this.form.DriverDoNo = this.form.DriverDoNo.toUpperCase();
    if (this.form.SupplierDoNo)
      this.form.SupplierDoNo = this.form.SupplierDoNo.toUpperCase();
    if (this.form.BehalfDoNo)
      this.form.BehalfDoNo = this.form.BehalfDoNo.toUpperCase();
    if (this.form.TicketNo)
      this.form.TicketNo = this.form.TicketNo.toUpperCase();
  }
}
